import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const referentsid = () => (
  <Layout>
    <SEO title="Referentsid" />

    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
            <h1>REFERENTSID</h1>
            <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
    </div>

    <div
      className="epoporandad"
      style={{
        maxWidth: 1300,
        margin: "auto",
      }}
    >
      <div className="wrapper-epo">
        <Link to="/ruumijagajad/voldikuksed/">
          <div className="epo">
            <h2>Voldikuksed</h2>
          </div>
        </Link>
        <Link to="/ruumijagajad/lootsuksed/">
          <div className="epo">
            <h2>Lõõtsuksed</h2>
          </div>
        </Link>
        <Link to="/ruumijagajad/siirdeseinad/">
          <div className="epo">
            <h2>Siirdeseinad</h2>
          </div>
        </Link>
        <Link to="/ruumijagajad/voldikseinad/">
          <div className="epo">
            <h2>Voldikseinad</h2>
          </div>
        </Link>
        <Link
          to="/ruumijagajad/referentsid/"
          activeStyle={{ borderBottom: "3px solid #ffa609" }}
        >
          <div className="epo">
            <h2>Referentsid</h2>
          </div>
        </Link>
      </div>

      <div className="referentsid-grid">
        <div>
          <p>Lietuvas Telekomas Vilnjus</p>
          <p>Hansabanka, Riia</p>
          <p>Grand Hotel Tallinn</p>
          <p>Radisson SAS hotell konverentsikeskus</p>
          <p>Oru hotell Tallinnas</p>
          <p>City hotell Portus Tallinnas</p>
          <p>Hotell Europa, Tallinnas</p>
          <p>Vanemuise Kontserdimaja</p>
          <p>Viljandi draamateater Ugala</p>
          <p>Tartu kohtumaja</p>
          <p>Kaitsepolitseiamet</p>
          <p>Tallinna Politseiprefektuur</p>
          <p>Tallinna Idaprefektuur</p>
          <p>Põhja Politseiprefektuur</p>
          <p>Tabasalu keskus-lasteaed</p>
          <p>Harku keskus-lasteaed</p>
          <p>Haabneeme lasteaed</p>
          <p>Lasteaed Kaseke, Laagri</p>
          <p>Büroohoone Tallinnas</p>
          <p>Kaupmees & Ko hoone</p>
          <p>Trükikoda Uniprint Tallinnas</p>
          <p>WTC büroohoone Tallinnas</p>
          <p>Hansapanga Tallina büroohoone</p>
          <p>DFDS Transport büroo Tallinnas</p>
          <p>Suure-Jaani Spordihall</p>
          <p>Elioni büroohoone</p>
          <p>Tallinna Botaanikaaed</p>
          <p>Balti Missionikeskus Tallinnas</p>
          <p>Tallinna Majandusgümnaasium</p>
          <p>Nõmme Spordikeskus</p>
        </div>
        <div>
          <p>Rapla Ühisgümnaasium</p>
          <p>Viljandi Kultuurikolledzh</p>
          <p>Rocca al Mare koolimaja</p>
          <p>Saku Gümnaasium</p>
          <p>Tabasalu Gümnaasium</p>
          <p>Suurupi Piirivalvekool</p>
          <p>Kiili Gümnaasium</p>
          <p>Haapsalu Kutsehariduskeskus</p>
          <p>AS YIT nõupidamiste ruumid</p>
          <p>Peugeot hoone Tallinnas</p>
          <p>Äri-ja eluhoone Pärnus (35 tk)</p>
          <p>Rakvere Spordihall</p>
          <p>Eesti Piirivalve büroohoone Tallinnas</p>
          <p>Eesti Energia büroohoone Tallinnas</p>
          <p>Türi Noortekeskus</p>
          <p>Türi Kultuurimaja</p>
          <p>Kammeri kool, Tartumaa</p>
          <p>Soojuselektrijaam, Pärnu</p>
          <p>Järva-Jaani lasteaed Jaanilill</p>
          <p>Koeru sosiaalhooldekeskus</p>
          <p>Järvamaa Kutsehariduskeskus, Paide</p>
          <p>Koidula tolli-piiripunkt, Koidula</p>
          <p>Kirna Rahvamaja</p>
          <p>Nõlvaku lasteaed, Laagri</p>
          <p>Lasteaed “Pingviin”, Narva</p>
          <p>Aqva-SPA konverentsikeskus, Rakvere</p>
          <p>Peetri kool-lasteaed, Peetri alevik</p>
          <p>Mustamäe haigla uus korpus, Tallinn</p>
          <p>Põlva Riigigümnaasium</p>
          <p>Rapla Riigigümnaasium</p>
          <p>Marja 7 spordiklubi</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default referentsid
